
import { defineComponent, computed, inject } from 'vue'
import { Group, DebugHelper, debugHelperDefault } from '@/store/types'
import { useSettingsStore } from '@/store/index'

export default defineComponent({
  name: 'Login',
  inject: ['logger'],
  setup () {
    const settingsStore = useSettingsStore()

    const personId = computed(() => settingsStore.personId)

    const logger: DebugHelper = inject('logger') || debugHelperDefault

    return {
      settingsStore,
      personId,
      logger
    }
  },
  data() {
    return {
      error: "",
      sessionCookie: "",
      username: null,
      password: null
    }
  },
  methods: {
    getLoginToken() {
      const url = "https://svd.church.tools/api/persons/" + this.settingsStore.personId + "/logintoken"
      fetch(url, { credentials: 'include' }).then(data => {
        return data.json()
      })
      .then(json =>  {
        this.logger.trace("login token", json)
        if (typeof json !== 'string' && 'data' in json) {
          this.settingsStore.setLoginToken(json['data'])
        }
      })
    },
    getTags() {
      const url = "https://svd.church.tools/api/persons/" + this.settingsStore.personId + "/tags"
      fetch(url, { credentials: 'include' }).then(data => {
        return data.json()
      })
      .then(json =>  {
        this.logger.trace("tags", json)
        if (typeof json !== 'string' && 'data' in json) {
          this.settingsStore.setTags(json['data'])
        }
      })
    },
    getUserInfo() {
      const url = "https://svd.church.tools/api/persons/" + this.settingsStore.personId
      fetch(url, { credentials: 'include' }).then(data => {
        return data.json()
      })
      .then(json =>  {
        this.logger.debug("get user info", json)
        if (typeof json !== 'string' && 'data' in json) {
          const user = json['data']
          this.settingsStore.setUser({ name: user.firstName + " " + user.lastName, email: user.email, id: user.cmsUserId })
        }
      })
    },
    getGroupsInfo() {
      const url = "https://svd.church.tools/api/persons/" + this.settingsStore.personId + "/groups"
      fetch(url, { credentials: 'include' }).then(data => {
        return data.json()
      })
      .then(json =>  {
        this.logger.debug("get groups info", json)
        if (typeof json !== 'string' && 'data' in json) {
          const groups = []
          for (const i in json['data']) {
            groups.push({ "name": json['data'][i].group.title, "role": json['data'][i].groupTypeRoleId, "shortName" : null} as Group)
          }
          this.settingsStore.setGroups(groups)
        }
      })
    },
    login() {
      const url = "https://svd.church.tools/api/login"
      fetch(url, {
        'method': 'POST',
        credentials: 'include',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'username': this.username, 'password': this.password })
      })
      .then(data => {
        // store the cookie if available, was never the case though
        if (data.headers.has('Set-Cookie')) {
          this.sessionCookie = data.headers.get('Set-Cookie') || ""
          this.settingsStore.setSessionCookie(this.sessionCookie)
        }
        return data.json()
      })
      .then(json => {
        // verify that it was successful logged in
        if ('data' in json && 'status' in json['data'] && json['data']['status'] === 'success') {
          // local store migration
          if (!Array.isArray(this.settingsStore.columns)) {
            this.logger.warn("would erase columns!")
            // this.$store.commit("setColumns", this.$store.getters.defaultColumns)
          }
          this.settingsStore.setPersonId(json['data']['personId'])
          this.getLoginToken()
          this.getGroupsInfo()
          this.getTags()
          this.getUserInfo()
          this.$router.push('/')
        } else {
          this.logger.error("error during login", json)
          const errorText = json['message']
          this.error = "error during login, reason: " + errorText
        }
      })
      .catch(error => {
        this.logger.error("error during login", error)
      })
    }
  }
});

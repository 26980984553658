
import Modal from '@/components/Modal.vue'
import draggable from 'vuedraggable'

import { defineComponent, computed, inject } from "vue";
import { Column, debugHelperDefault, DebugHelper, WebSocketHelper } from '@/store/types'
import { useSettingsStore } from '@/store/index'

export default defineComponent({
  inject: ['logger', 'messagingService'],
  components: {
    Modal,
    draggable,
  },
  setup () {
    const settingsStore = useSettingsStore()

    const isMaster = settingsStore.isMaster

    const synchronize = computed({
      get() {
        return settingsStore.localMode == false
      },
      set(value: boolean) {
        settingsStore.setLocalMode(!value)
      }
    })

    const messagingService: WebSocketHelper | undefined = inject('messagingService')
    const logger: DebugHelper = inject('logger') || debugHelperDefault

    const debug = computed({
      get() {
        return settingsStore.debugLog
      },
      set(value: boolean) {
        settingsStore.setDebugLog(value)
      }
    })

    const columnsArray = computed({
      set(columns: Column[]) {
        console.log("set columns")
        settingsStore.setColumns(columns);
      },
      get() {
        return settingsStore.columns.filter(function(val) { if (val) return val })
      }
    })

    return {
      settingsStore,
      synchronize,
      debug,
      columnsArray,
      isMaster,
      messagingService,
      logger
    }
  },
  data() {
    return {
      showSettings: false,
      showUserMenu: false
    }
  },
  methods: {
    setColumns(columns: Column[]) {
      this.settingsStore.setColumns(columns);
    }
  },
  created() {
    const url = location.origin.replace(/^http/, 'ws')
    if (this.messagingService) {
      this.messagingService.connect(url)
    } else if (this.logger) {
      this.logger.error("no websocket available")
    } else {
      console.error("no websocket and debugger available")
    }
  }
})

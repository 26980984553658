import type { RouteRecordRaw } from 'vue-router';

import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import LoginPage from '../views/LoginPage.vue'
import RunDown from '../views/RunDown.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/rundown',
    name: 'RunDown',
    component: RunDown
  },
  {
    path: '/stage',
    name: 'StageView',
    component: RunDown
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
})

export default router

import { createApp } from 'vue'
import './registerServiceWorker'

import router from './router'

import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { useSettingsStore } from '@/store/index'
import { DebugHelper, WebSocketHelper } from '@/store/types'

import App from './App.vue'

// TODO need to disable it in a different way
// Vue.config.productionTip = false

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App)
app.use(pinia)

const store = useSettingsStore()

const debug = {
  // later also support levels
  defaultLevel: 0,
  debug: function(...args: any[]) {
    if (store && store.debugLog) {
      // console.error.apply(console, args)
      const context = "DEBUG:"
      return Function.prototype.bind.call(console.log, console, context)
    } else {
      return function() {}
    }
  }(),
  error: function(...args: [any?, ...any[]]) {
    if (store && store.debugLog) {
      // console.error.apply(console, args)
      const context = "ERROR:"
      return Function.prototype.bind.call(console.error, console, context)
    } else {
      return function() {}
    }
  }(),
  warn: function(...args: any[]) {
    if (store && store.debugLog) {
      // console.error.apply(console, args)
      const context = "WARN:"
      return Function.prototype.bind.call(console.warn, console, context)
    } else {
      return function() {}
    }
  }(),
  trace: function(...args: any[]) {
    if (store && store.debugLog) {
      // console.error.apply(console, args)
      const context = "TRACE:"
      return Function.prototype.bind.call(console.log, console, context)
    } else {
      return function() {}
    }
  }(),
} as DebugHelper

app.provide('logger', debug)
app.provide('messagingService', {
  debug: debug,
  store: pinia,
  ws: null as any,
  url: null as string | null,
  timerId: 0 as number,
  init: function() {
    debug.trace("init", this)
  },
  connect: function(url: string | null, f: any) {
    this.init()
    const self = this
    if (url != null) {
      // update url
      this.url = url
    }
    if (this.url == null) {
      this.debug && this.debug.error("No URL set to connect to the WebSocket server!")
      return
    } else {
      this.debug && this.debug.debug("connecting to " + this.url + "...")
    }
    this.ws = new WebSocket(this.url)
    this.ws.onopen = function () {
      self.debug && self.debug.debug('websocket connection successfully established')
      if (self.timerId) {
        clearTimeout(self.timerId)
        self.timerId = 0
      }
      if (f != null) {
        f()
      }
    }
    this.ws.onerror = function (error: object) {
      self.debug && self.debug.error('websocket error', error)
    }
    this.ws.onmessage = function (event: any) {
      // DEBUG console.log('websocket onmessage', event)
      const data = JSON.parse(event['data'])
      if (data.type === 'location') {
        // currently we only support this event
        store.setIncomingMessage(data)
      }
    }
    this.ws.onclose = function(event: object) {
      self.debug && self.debug.debug('websocket closed, will reconnect...', event)

      // retry again in 1s if no other time was already set
      if (!self.timerId) {
        self.timerId = setTimeout(function() {
        self.ws = null
        self.connect(self.url, f)
        }, 1000)
      }
    }
  },
  send: function(s: string) {
    const self = this
    this.debug && this.debug.trace("send", this.ws, this)
    if (this.ws === null || this.ws.readyState > 1) {
      this.debug && this.debug.debug("triggering websocket connect for", self.url)
      this.connect(self.url, function() {
        self.ws.send(s)
      })
      // TODO or rather put to a queue?
    } else if (this.ws.readyState === 1) {
      this.debug && this.debug.trace("sending websocket message", s)
      this.ws.send(s)
    } else if (this.ws.readyState === 0) {
      // let's try again in one second
      // console.log("waiting for websocket")
      this.debug && this.debug.debug("waiting as the websocket connection is not open yet")
      setTimeout(function() {
        self.ws.send(s)
      }, 1000)
    } else {
      // console.log("issue with websocket", this.ws.readyState)
      this.debug && this.debug.error("websocket issue: cannot send, state: %s", this.ws.readyState)
    }
  }
} as WebSocketHelper)

app.use(router)
app.mount('#app')
import { defineStore } from 'pinia'
import { RootState, RoleDefinition, Group, Tag, User, Column } from './types'

// roles
enum ROLE {
  TECHNIK_MITARBEITER = 15,
  TECHNIK_LEITER = 16,
  TECHNIK_ORGANISATOR = 19,
  GROUP_TEILNEHMER = 29,
  GROUP_LEITER = 30,
  GROUP_BEANTRAGT = 34,
  GROUP_LOESCHEN = 35
}

const DEFAULT_COLUMNS = [
  { name: 'Position', id: 'position', editable: false, value: true },
  { name: 'Titel', id: 'title', editable: false, value: true },
  { name: 'Notizen', id: 'notes', editable: true, value: false },
  { name: 'Verantwortlicher', id: 'responsible', editable: true, value: false },
  { name: 'Startzeit', id: 'startTime', editable: true, value: false },
  { name: 'Dauer', id: 'duration', editable: true, value: false },
  { name: 'Gruppennotizen', id: 'groupnotes', editable: true, value: false },
]

const ROLE_DEFINITIONS: { [key: number]: RoleDefinition } = {
  [ROLE.TECHNIK_MITARBEITER]: {
    shortName: "Mitarbeiter"
  },
  [ROLE.TECHNIK_LEITER]: {
    shortName: "Leiter"
  },
  [ROLE.TECHNIK_ORGANISATOR]: {
    shortName: "Organisator"
  },
  [ROLE.GROUP_TEILNEHMER]: {
    shortName: "Teilnehmer"
  },
  [ROLE.GROUP_LEITER]: {
    shortName: "Leiter"
  },
  [ROLE.GROUP_BEANTRAGT]: {
    shortName: "beantragt"
  },
  [ROLE.GROUP_LOESCHEN]: {
    shortName: "zu löschen"
  },
}

// Create a new store instance
export const useSettingsStore = defineStore('base/settings', {
  state: (): RootState => ({
    user: null,
    version: 'something',
    sessionCookie: null,
    loginToken: null,
    groups: [],
    tags: [],
    personId: null,
    incomingMessage: null,
    localMode: false,
    debugLog: false,
    columns: JSON.parse(JSON.stringify(DEFAULT_COLUMNS)),
  }),
  actions: {
    setPersonId (value: string) {
      this.personId = value
    },
    setSessionCookie (value: string) {
      this.sessionCookie = value
    },
    setLoginToken (value: string) {
      this.loginToken = value
    },
    setGroups (groups: Group[]) {
      this.groups.length = 0
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        // console.log("role definitions", ROLE_DEFINITIONS, group.shortName == undefined || group.shortName === null, ROLE_DEFINITIONS[group.role], group)
        if (group.shortName == undefined || group.shortName === null) {
          // try to find the shortName
          if (group.role in ROLE_DEFINITIONS) {
            group.shortName = ROLE_DEFINITIONS[group.role].shortName
          } else {
            // using fallback for shortName
            group.shortName = group.name
          }
        }
        this.groups.push(group)
      }
    },
    setTags (tags: Tag[]) {
      this.tags = tags
    },
    setUser (value: User | null) {
      this.user = value
    },
    setIncomingMessage (value: object | null) {
      this.incomingMessage = value
    },
    setLocalMode (value: boolean) {
      this.localMode = value
    },
    setDebugLog (value: boolean) {
      this.debugLog = value
    },
    setColumns (value: Column[]) {
      this.columns = value
    }
  },
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage }
    ]
  },
  getters: {
    isMaster: (state: RootState) => {
        if (state.tags) {
          for (let i = 0; i < state.tags.length; i++) {
            const tag = state.tags[i]
            if (tag.name === "churchflo-master") {
              return true
            }
          }
        }
        return false
      /* old logic
      if (state.groups) {
        for (let i = 0; i < state.groups.length; i++) {
          let group = state.groups[i]
          if (group.name === "Admin") {
            return true
          }
          if (group.name === "Technik") {
            return group.role === ROLE.TECHNIK_ORGANISATOR || group.role === ROLE.TECHNIK_LEITER
          }
        }
      }
      */
    },
    defaultColumns() {
      return JSON.parse(JSON.stringify(DEFAULT_COLUMNS))
    },
  },  
})

import { defineComponent, inject, reactive } from 'vue'
import { Column, DebugHelper, debugHelperDefault, WebSocketHelper } from '@/store/types'
import { useSettingsStore } from '@/store/index'

export interface Person {
  title: string
}

export interface ServiceGroupNote {
  note: string,
  serviceGroupId: number
}

export interface ResponsiblePerson {
  accepted: boolean,
  person: Person,
  service: string
}

export interface Responsible {
  text: string,
  persons: ResponsiblePerson[]
}

export interface Item {
  title: string,
  note: string,
  position: number,
  type: string,
  start: string,
  isBeforeEvent: boolean,
  startTimes: any,
  duration: number,
  responsible: Responsible,
  serviceGroupNotes: ServiceGroupNote[],
  meta: any,
  song: Song
}

export interface Song {
  songId: number,
  arrangementId: number,
  title: string,
  arrangement: string,
  category: string,
  key: string,
  bpm: string,
  isDefault: boolean
}

export interface Agenda {
  id: null | number,
  items: Item[]
}

export default defineComponent({
  name: 'RunDown',
  inject: ['messagingService', 'logger'],
  setup () {
    const settingsStore = useSettingsStore()
    const agenda: Agenda = reactive({ id: null, items: [] })
    const messagingService: WebSocketHelper | undefined = inject('messagingService')
    const logger: DebugHelper = inject('logger') || debugHelperDefault
    
    return {
      settingsStore,
      agenda,
      messagingService,
      logger
    }
  },
  data() {
    return {
      agenda: this.agenda,
      error: '',
      stageView: false,
      selectedRow: 0,
      timerId: 0,
      clockTimerInterval: 0,
      clockTime: '00:00:00',
      cueTimerInterval: 0,
      cueTime: 0,
      cueTimeDisplay: '00:00',
      cueTimeOverDisplay: '',
      settingsStore: this.settingsStore,
    }
  },
  computed: {
    items(): Item[] {
      if (this.agenda != null) {
        this.agenda.items.sort((a, b) => (a.position > b.position) ? 1 : -1)
        return this.agenda.items
      } else {
        return []
      }
    },
    incomingMessage(): object | null {
      return this.settingsStore.incomingMessage
    },
    broadcasting(): boolean {
      return this.settingsStore.isMaster && this.settingsStore.localMode === false
    },
    visibleColumnsArray(): Column[] {
      return this.settingsStore.columns.filter(function(val: Column) { if (val && val.value === true) return val })
    },
  },
  watch: {
    // whenever the selected row changes, this function will run
    selectedRow: function (newValue, oldValue) {
      // if this user is master and local mode is disabled distribute location
      if (this.settingsStore.isMaster && this.settingsStore.localMode === false) {
        this.sendCurrentRow()
      } else {
        this.logger.trace("will not send out the selected row as either user is not master and / or local mode is enabled", this.settingsStore.isMaster, this.settingsStore.localMode)
      }
      // scroll into view
      // window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
      let el = document.getElementById('row_' + newValue)
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      // clear existing timer and start new one
      if (this.cueTimerInterval) {
        clearInterval(this.cueTimerInterval)
      }
      this.cueTime = 0
      this.cueTimerInterval = window.setInterval(() => {
        this.cueTime = this.cueTime + 1
        const seconds = this.cueTime % 60
        const minutes = Math.floor(this.cueTime / 60)
        this.cueTimeDisplay = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)

        if (this.items && this.selectedRow && this.selectedRow in this.items && this.items[this.selectedRow].duration > 0 && this.cueTime > this.items[this.selectedRow].duration) {
          // calculate over time
          const duration = this.items[this.selectedRow].duration
          const overtimeSeconds = (this.cueTime - duration) % 60
          const overtimeMinutes = Math.floor((this.cueTime - duration) / 60)
          this.cueTimeOverDisplay = (overtimeMinutes < 10 ? "0" + overtimeMinutes : overtimeMinutes) + ":" + (overtimeSeconds < 10 ? "0" + overtimeSeconds : overtimeSeconds)
        } else {
          this.cueTimeOverDisplay = ''
        }
      }, 1000)
    },
    incomingMessage: function (newValue, oldValue) {
      // only work on the new value, disregard old one
      // only select row if local mode is disabled
      this.logger.debug("handling incoming message", newValue)
      if (newValue.type === 'location' && this.settingsStore.localMode === false) {
        this.selectedRow = newValue.row
      }
    }
  },
  methods: {
    advance() {
      this.logger.trace("advance", this.selectedRow)
      // TODO don't advance if not master!
      if (this.agenda != null && this.agenda.items != null && Array.isArray(this.agenda.items)) {
        this.selectedRow = this.selectedRow < this.agenda.items.length-1 ? this.selectedRow + 1 : 0
        // skip over header
        if (this.agenda.items[this.selectedRow].type == 'header') {
          this.selectedRow = this.selectedRow < this.agenda.items.length-1 ? this.selectedRow + 1 : 0
        }
      }
    },
    selectRow(index: number) {
      // TODO don't select if not master!
      this.selectedRow = index
    },
    getPosition(index: number) {
      if (this.agenda != null && Array.isArray(this.agenda.items)) {
        let pos = 0
        for (let i = 0; i < this.agenda.items.length; i++) {
          if (this.agenda.items[i].type != 'header') {
            pos++
          }
          if (index == i) {
            return pos
          }
        }
      }
      return 0
    },
    loadAgenda(id: string) {
      const url = "https://svd.church.tools/api/events/" + id + "/agenda"
      fetch(url, { credentials: 'include' }).then(data => {
        if (data.status == 401) {
            this.logger.debug("401, going to login")
            this.settingsStore.setUser(null)
            this.$router.push('/login')
        } else if (data.status != 200) {
            this.logger.error("other error displaying error")
            this.error = "could not fetch event agenda, reason: " + data.statusText
        } else {
          this.logger.trace("all good returning json")
          return data.json()
        }
      })
      .then(json => {
        this.logger.debug("event agenda", json)
        if (json != undefined && json && json.data) {
          this.agenda.id = json.data.id
          this.agenda.items = json.data.items

          // subscribe to changes
          this.subscribeForEvents()
        } else {
          this.logger.warn("WARNING: no data available")
        }
      })
      .catch(error => {
        this.logger.error("error during getting event agenda", error)
        // this.$router.push('/login')
      })
    },
    subscribeForEvents() {
      if (this.agenda != null && this.agenda.id != null && this.messagingService) {
        this.messagingService.send(JSON.stringify({ type: 'subscribe', eventId: this.agenda.id, user: this.settingsStore.personId }))
      }
    },
    unsubscribeForEvents() {
      if (this.agenda != null && this.agenda.id != null && this.messagingService) {
        this.messagingService.send(JSON.stringify({ type: 'unsubscribe', eventId: this.agenda.id, user: this.settingsStore.personId }))
      }
    },
    sendCurrentRow() {
      if (this.agenda != null && this.agenda.id != null && this.messagingService) {
        this.messagingService.send(JSON.stringify({ type: 'location', eventId: this.agenda.id, user: this.settingsStore.personId, row: this.selectedRow }))
      } else {
        this.logger.warn("agenda or agenda id not given, cannot send out row selection!")
      }
    },
  },
  mounted() {
    this.logger.trace("mounted", this.$route.name)
    if (this.$route.name == "StageView") {
      this.stageView = true
    }

    // fetch event details for the given event
    this.logger.trace("route query", this.$route.query)
    if (this.$route.query.id) {
      this.loadAgenda(this.$route.query.id as string)
    }

    // allow the space button to advance the flow
    const self = this; 
    window.addEventListener('keyup', function(ev) {
      if (ev.code === "Space") {
        self.advance()
      }
    })
    window.addEventListener('keydown', function(e) {
        // don't allow scrolling with space anymore
      if(e.keyCode == 32 && e.target == document.body) {
        e.preventDefault();
      }
    })

    // update timer every second
    this.clockTimerInterval = window.setInterval(() => {
      const date = new Date()
      const isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(11, 19)
      this.clockTime = isoDateTime
    }, 1000)
  },
  destroyed() {
    this.logger.trace("destroyed")
    this.unsubscribeForEvents()
    if (this.clockTimerInterval) {
      clearInterval(this.clockTimerInterval)
    }
  }
})

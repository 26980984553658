export interface User {
  id: string | null;
  name: string | null;
  email: string | null;
}

export interface Group {
  name: string;
  role: number;
  shortName: string | null;
}

export interface Tag {
  name: string;
}

export interface Column {
  name: string;
  id: string;
  editable: boolean;
  value: boolean;
}

export interface RoleDefinition {
  shortName: string | null;
}

export interface RootState {
  user: User | null;
  version: string;
  sessionCookie: string | null;
  personId: string | null;
  loginToken: string | null;
  groups: Group[];
  tags: Tag[];
  incomingMessage: object | null;
  localMode: boolean;
  debugLog: boolean;
  columns: Column[];
}

export interface DebugHelper {
  defaultLevel: number,
  debug: Function,
  trace: Function,
  error: Function,
  warn: Function
}

export const debugHelperDefault: DebugHelper = {
  defaultLevel: 0,
  debug: function() { },
  error: function() { },
  warn: function() { },
  trace: function() { }
}

export interface WebSocketHelper {
  init: Function,
  connect: Function,
  send: Function,
  debug: DebugHelper,
  store: any,
  ws: any,
  url: string | null,
  timerId: number
}

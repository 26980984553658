
// @ is an alias to /src
import Login from '@/components/Login.vue'

import { defineComponent, inject } from 'vue'
import { DebugHelper, debugHelperDefault } from '@/store/types'
import { useSettingsStore } from '@/store/index'

export default defineComponent({
  name: 'Home',
  inject: ['logger'],
  setup () {
    const settingsStore = useSettingsStore()
    const logger: DebugHelper = inject('logger') || debugHelperDefault
    return {
      settingsStore,
      logger
    }
  },
  data() {
    return {
      error: "",
      eventlist: []
    }
  },
  components: {
    Login
  },
  methods: {
    showRunDown(id: String) {
      this.$router.push('/rundown?id=' + id)
    },
    loadEventList() {
      const url = "https://svd.church.tools/api/events"
      fetch(url, { credentials: 'include' }).then(data => {
        if (data.status == 401) {
          this.logger.debug("401, going to login")
          this.settingsStore.setUser(null)
          this.$router.push('/login')
        } else if (data.status != 200) {
          this.logger.error("other error displaying error")
          this.error = "could not fetch event list, reason: " + data.statusText
        } else {
          this.logger.trace("all good returning json")
          return data.json()
        }
      })
      .then(json => {
        this.logger.debug("event list", json)
        if (json && json.data) {
          this.eventlist = json.data
        }
      })
      .catch(error => {
        this.logger.error("error during getting event list", error)
        // this is an error and not a 401, no reauthenticate
      })
    }
  },
  created() {
    this.loadEventList()
  },
})

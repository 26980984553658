
import Login from '../components/Login.vue'

import { defineComponent } from 'vue';

export default defineComponent({
  name: "LoginPage",
  components: {
      Login
  }
})
